#proformaPdf {
    font-family: 'Roboto', sans-serif;
    color: #333;

    // Estilos para el encabezado
    h1 {
        font-size: 28px;
        color: #34495E;
        margin-bottom: 10px;
        text-align: center;
    }

    h2 {
        font-size: 20px;
        color: #34495E;
        margin-bottom: 8px;
        border-bottom: 2px solid #ddd;
        padding-bottom: 5px;
    }

    p {
        font-size: 14px;
        line-height: 1.6;
        margin: 5px 0;
    }

    // Estilos para tablas
    table {
        width: 100%;
        border-collapse: collapse;

        th, td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: left;
        }
        
        th {
            background-color: #ecf0f1;
            font-weight: 600;
            text-transform: uppercase;
        }

        // Alternar colores en las filas
        tr:nth-child(even) {
            background-color: #f9f9f9;
        }
    }

    // Estilos para imágenes
    img {
        max-width: 120px;
        height: auto;
        display: block;
        margin: 0 auto;
    }

    // Estilos para secciones
    .section {
        background-color: #f8f9fa;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    // Estilos para firma y footer
    .signature {
        text-align: center;
        margin-top: 40px;

        p {
            margin: 5px 0;
        }

        .signature-line {
            margin-bottom: 40px;
            border-bottom: 1px solid #333;
            width: 200px;
            margin: 0 auto;
        }
    }

    footer {
        margin-top: 40px;
        text-align: center;
        font-size: 12px;
        color: #7F8C8D;
    }
}

// Estilos generales para la página de la proforma
.Proforma {
    &__container {
        padding: 20px;
        background: #f9f9f9;
        border-radius: 10px;
    }

    &__head {
        margin-bottom: 20px;
    }

    &__body {
        .table {
            background: #ffffff;

            th {
                background-color: #28314A;
                color: #ffffff;
                text-align: center;
                padding: 8px;
                font-size: 14px;
            }

            td {
                vertical-align: middle;
                padding: 8px;
                font-size: 14px;
            }

            input, textarea {
                margin: 0;
            }
        }

        .table-bordered th,
        .table-bordered td {
            border: 1px solid #ddd;
        }
    }
}
