/* Trabajadores.scss o un archivo CSS separado */
.modal-columnas .modal-body {
    max-height: 400px;
    overflow-y: auto;
}

.modal-columnas .form-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e9ecef;
}


.icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Ajusta este tamaño según tus necesidades */
    height: 30px; /* Ajusta este tamaño según tus necesidades */
  }
  
  .icon {
    font-size: 1.2rem; /* Ajusta el tamaño del icono según tus necesidades */
  }
  