/* Estilo unificado para todas las secciones */
.DesempenoDocente {
  .ranking-container,
  .proyecto-mejor-card,
  .lista-promedios-container {
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    padding: 20px;
  }

  .proyecto-mejor-card{
    width: 205%;
  }

  .ranking-card {
    text-align: center;
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background: #f4f4f4;

    &.ranking-position-1 {
      border: 3px solid gold;
    }
    &.ranking-position-2 {
      border: 3px solid silver;
    }
    &.ranking-position-3 {
      border: 3px solid bronze;
    }
  }

  .ranking-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #ffc107;
    border-radius: 50%;
    padding: 5px;
    color: white;

    &.badge-1 {
      background: gold;
    }
    &.badge-2 {
      background: silver;
    }
    &.badge-3 {
      background: bronze;
    }
  }

  .proyecto-info {
    text-align: center;

    .nombre-proyecto {
      color: #444;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .proyecto-metricas {
      .metric-item {
        text-align: center;
        background: #fff;
        padding: 10px 15px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      }
    }

    .titulo-proyecto {
      font-weight: bold;
      color: #333;
    }

    .icono-metrica {
      font-size: 30px;
      color: #1976d2;
    }
  }

  .tabla-react {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 12px;
      border: 1px solid #ddd;
    }

    th {
      background-color: #1976d2;
      color: white;
      text-align: center;
    }

    td {
      text-align: center;
    }
  }

  .pagination {
    margin-top: 10px;
    text-align: center;

    button {
      margin: 0 5px;
      padding: 5px 10px;
      background-color: #1976d2;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:disabled {
        background-color: #ccc;
      }
    }
  }
  .form-control {}
}