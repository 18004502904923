.filtro .SimpleSelect__menu {
    z-index: 1000; /* Ajusta el valor si es necesario */
  }
  .encuestas {
    overflow: visible; /* Asegúrate de que el contenedor no corte las opciones del select */
  }
  .encuestas .container-fluid {
    overflow-y: auto; /* Permite hacer scroll dentro del contenedor */
    max-height: 400px; /* Define una altura máxima si es necesario */
  }
    