
/*******************************
*******  Formularios
*******************************/

label.label-required::after{
  content: '*';
  color: $danger;
  margin: 0px 5px;
}

label {
  color: #6e6e6e;
}

.form-control {
  font-size: 13px;
  color: lighten($color: $color-primary, $amount: 10);
  height: 35px;
  padding: 4px 16px;
  border-radius: 3px;
  &:focus, &:active {
    border: 1px solid lighten($color: $primary, $amount: 55);
  }
  &:disabled {
    border-color: #c9c9c9;
  }
}

.form-group {
  margin-bottom: 15px;
}

.input-group-text {
  font-size: 13px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.form-row > [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.form-header {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1rem;
  svg {
    margin-right: 6px;
  }
}
