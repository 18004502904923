
/* Variables */
$purple: #b4a3ff;
$yellow: #ffda6d;
$green: #74f3a5;
$blue: #e9f2fe;
$shadow: #e0ecfb;
/* Elements */

#header h3 {
  text-align: center;
  font-size: 3em;
  margin-top: 1em;
  padding-bottom: 0.5em;
  font-family:'Catamaran';
  font-weight: 500;
  span {
    font-size: 0.66em;
    color: $yellow
  }
}

#user-list{
  padding: 0 20px;
}

#user-list li { 
  list-style:none;
}

/* Classes */
#user-list li .avatar { 
  border-radius: 100%;
}
#user-list li .avatar-sm { 
  max-width: 64px;
  height:auto;
  margin-right: 1em;
}
#user-list li .avatar-lg{
  border-style: double;
  border-color: #ddd;
  border-width: 0.25em;
  box-shadow: 0px 2px 4px $blue;
  width:150px;
  height:150px;
  margin-left: 25%;
  margin-right: 25%;
}
.brownies {
  font-size: 0.75em;
  color: #888;
}
#user-list .leader{
  display: inline-block;
  vertical-align: text-bottom;
  text-align: center;
  width: 33%;
  z-index: 1;
  position: relative;
  
    .list-index{
      background-color: $green;
      border-radius: 100%;
      width: 1.45em;
      height: 1.45em;
      vertical-align: middle;
      line-height: 1.4em;
      font-size: 1em;
      color: white;
      font-weight: 700;
      position: absolute;
      left: 47%;
      bottom: 14%;
    }
    .img-copa{
      position: absolute;
    }
    &:nth-child(1){
      .list-index{
        width: 1.5em;
        height: 1.5em;
        line-height: 1.45em;
        font-size: 1em;
        background-color: $yellow;
      }
     .avatar-lg{
        width: 210px;
        height: 210px;
        border-color: gold;
        box-shadow: 0px 2px 4px gold;
        margin-left: 16%;
        margin-right: 16%;

     }
    }
    &:nth-child(2){
      float:left;
      padding-top: 6em;
      .list-index{
        background-color: $purple;
      }
     
       .avatar-lg{
        border-color:silver;
        box-shadow: 0px 2px 4px silver;
       }
    }
  
    &:nth-child(3){
     float:right;
     padding-top: 6em;
     .avatar-lg{
      border-color:#CD7F32;
      box-shadow: 0px 2px 4px #CD7F32;
     }
    } 
}
#user-list li .list-index{
  display: inline-block;
}
#user-list .list-sm{
  clear:both;
  margin: 1em 2em;
  padding: 2em 0;
  box-shadow: 0px 1px 1px #e9f2fe;

  * {
    vertical-align: middle;
  }
  .list-index{
    margin: 0;
    padding-right: 2em;
    margin-top:10px
  }

  .userinfo{
    padding:0;
    margin-top:10px
  }
  .userinfo .username{
    font-size: 0.95em;
    margin:0;
  }
}
#user-list li .userinfo{
  display: inline-block;
}
#user-list li .userinfo .username{
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-top:35px;
}

#contenedor-ranking{
  //background-color: #fff;
  //box-shadow: 0px 2px 2px 2px $shadow;
  //border-radius: 2px;
  max-width: 70em;
  margin:auto;
}
#header{
  padding-top: 0.5em;
  padding-right: 1em;
  font-size: 0.95em;
}

