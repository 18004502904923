// Asegura que el contenido no desborde el contenedor principal
.container-fluid, .container {
  max-width: 100%; // Para asegurarse de que el container no excede el tamaño máximo de la pantalla
  overflow-x: hidden; // Oculta el desbordamiento horizontal
}

.DatosMetodoPago__nombre-proyecto {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  
  // Si es necesario, se puede truncar el texto largo
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Asegura que el contenido del plan esté dentro de los límites del diseño
.contenido-plan {
  max-width: 100%; // Evita desbordamientos
  overflow: auto; // Habilita el desplazamiento si el contenido es demasiado grande
}
